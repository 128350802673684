export const TempusProject = () => ({
  comboboxType: "projects",
  nextElementId: "combobox-issues",
  close: true,

  initProject() {
    this.fetchProjects()
  },

  fetchProjects(projects = []) {
    if (projects.length == 0) {
      this.itemlists["projects"] = []
      this.projects = {}
    }
    this.fetchGETSomething(
      "/project/search",
      "&maxResults=50&startAt=" + projects.length + "&status=live"
    )
      .then((result) => {
        projects = projects.concat(result.values)
        if (result.total > projects.length) {
          return this.fetchProjects(projects)
        } else {
          for (const value of projects) {
            this.projects[value.key] = value
            this.itemlists["projects"].push(value.key)
          }
        }
        return projects
      })
      .then(() => {
        this.itemlistsActive["projects"] = this.itemlists["projects"]
      })
      .catch((err) => console.log(err))
  },

  fetchProject(projectKey) {
    this.fetchGETSomething("/project/" + projectKey)
      .then((result) => {
        if (!result.err) {
          this.projects[result.key] = result
          return result
        }
      })
      .catch((err) => console.log(err))
  },

  setProject(projectKey) {
    if (this.projects.hasOwnProperty(projectKey)) {
      this.projectSelected = this.projects[projectKey]
    } else {
      this.projectSelected = this.fetchProject(projectKey)
    }
    if (
      this.issueSelectedData &&
      this.issueSelectedData.fields &&
      this.issueSelectedData.fields.project.key !== projectKey
    ) {
      this.issueSelected = {}
      this.issueSelectedData = {}
      this.itemlistsActive["issues"] = []
      document.getElementById("combobox-issues").value = ""
    }
    document.getElementById("combobox-projects").value =
      this.projectSelected.key + "- " + this.projectSelected.name
    return true
  }
})
