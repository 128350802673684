import {
  computePosition,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow
} from "@floating-ui/dom"

// This is a workaround for a bug in ResizeObserver
window.addEventListener("error", function (event) {
  if (
    event.message &&
    event.message.includes(
      "ResizeObserver loop completed with undelivered notifications"
    )
  ) {
    event.stopImmediatePropagation()
    event.preventDefault()
    return false
  }
})

export const TempusHelper = () => ({
  init() {}
})

export const floatingTooltip = (config = {}) => ({
  tooltipText: config.tooltipText || "",
  placement: config.placement || "top",
  offsetValue: config.offset || 8,
  open: false,
  tooltipEl: null,
  referenceEl: null,
  init() {
    this.tooltipEl = this.$refs.tooltip
    this.referenceEl = this.$el

    this.updatePosition()

    autoUpdate(this.referenceEl, this.tooltipEl, () => {
      this.updatePosition()
    })
  },
  updatePosition() {
    if (!this.referenceEl || !this.tooltipEl) {
      return
    }
    // Get a reference to the arrow element.
    const arrowEl = this.$refs.arrow
    computePosition(this.referenceEl, this.tooltipEl, {
      placement: this.placement,
      middleware: [
        offset(this.offsetValue),
        flip(),
        shift({ padding: 5 }),
        arrow({ element: arrowEl })
      ]
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(this.tooltipEl.style, {
        left: `${x}px`,
        top: `${y}px`
      })
      if (arrowEl) {
        const { x: arrowX, y: arrowY } = middlewareData.arrow || {}
        const staticSide = {
          top: "bottom",
          right: "left",
          bottom: "top",
          left: "right"
        }[placement.split("-")[0]]

        Object.assign(arrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : "",
          top: arrowY != null ? `${arrowY}px` : "",
          [staticSide]: "-4px"
        })
      }
    })
  }
})
