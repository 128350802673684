import "./index.css"

import { TempusData } from "./js/tempus-data.js"
import { TempusWorklog } from "./js/tempus-worklog.js"
import { TempusUser } from "./js/tempus-user.js"
import { TempusIssue } from "./js/tempus-issue.js"
import { TempusProject } from "./js/tempus-project.js"
import { TempusProjectForTimesheet } from "./js/tempus-project-for-timesheet.js"
import { TempusCustomer } from "./js/tempus-customer.js"
import { TempusMenu } from "./js/tempus-menu.js"
import { TempusHelper, floatingTooltip } from "./js/tempus-helper.js"
import { Tempus } from "./js/tempus.js"

import Alpine from "alpinejs"
import persist from "@alpinejs/persist"
import focus from "@alpinejs/focus"

window.Alpine = Alpine

Alpine.data("Tempus", Tempus)
Alpine.data("TempusData", TempusData)
Alpine.data("TempusWorklog", TempusWorklog)
Alpine.data("TempusUser", TempusUser)
Alpine.data("TempusProject", TempusProject)
Alpine.data("TempusIssue", TempusIssue)
Alpine.data("TempusProjectForTimesheet", TempusProjectForTimesheet)
Alpine.data("TempusCustomer", TempusCustomer)
Alpine.data("TempusMenu", TempusMenu)
Alpine.data("TempusHelper", TempusHelper)
Alpine.data("floatingTooltip", floatingTooltip)

Alpine.plugin(persist)
Alpine.plugin(focus)

import "./js/tempus-store.js"

Alpine.start()
