export const TempusMenu = () => ({
  isMobileLeftSidebarOpen: false,
  isMobileRightColOpen: Alpine.$persist(true),
  mainMenu: [
    {
      label: "Dashboard",
      target: "dashboard",
      path: "m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
    },
    {
      label: "Zeiterfassung",
      target: "tracking",
      path: "M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    },
    {
      label: "Arbeitszeiten",
      target: "tracking-history",
      path: "M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
    },
    {
      label: "Abrechnung",
      target: "accounting-timesheet",
      path: "M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    }
  ],
  init() {},
  switchMain(target) {
    this.$store.tempus.showPanel = target
  }
})
