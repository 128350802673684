import Alpine from "alpinejs"
Alpine.store("tempus", {
  viewOnlyMyIssues: false,
  viewNoDoneIssues: true,
  atlassianUrl: "https://medienagenten.atlassian.net/",
  proxyUrl: "./index.php",
  viewEachWorklog: false,
  fetchNotChargedOnly: false,
  fetchDoneOnly: false,
  trackingStatusActive: "Tracking stoppen",
  trackingStatusOff: "Tracking starten",
  trackingStatusError: "Something went wrong, still tracking",
  showPanel: "dashboard",
  loading: 0,
  notifications: []
})
